.widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  padding: 10px 10px 5px;

  &.widget_show-header-on-hover-only {
    .widget__header {
      display: none;
    }

    &:hover {
      .widget__header {
        display: flex;
      }
    }
  }

  .widget__header {
    display: flex;
    height: 30px;
    border-bottom: 1px solid var(--border-color);
    padding: 0 0 8px;
    margin-bottom: 8px;

    .widget_title {
      font-family: Consolas, monospace;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
    }

    .icons {
      right: 0;
      margin-top: 0;
      padding-left: 11px;
      // no wrap
      flex-shrink: 0;
    }

    [class*="fa-"] {
      user-select: none;
      cursor: pointer;
      width: 13px;
      height: 13px;
      margin: 0 3px;
    }
  }
}

.markets-placeholder {
  font-family: Consolas, monospace;
}