@import "vue-toastification/src/scss/_variables";
@import "vue-toastification/src/scss/_toastContainer";
@import "vue-toastification/src/scss/_toast";
@import "vue-toastification/src/scss/_closeButton";
@import "vue-toastification/src/scss/_progressBar";
@import "vue-toastification/src/scss/_icon";
@import "vue-toastification/src/scss/animations/_bounce";

// todo: better styles
.#{$vt-namespace}__toast--default {
	background-color: var(--border-color);
	color: var(--text-color);
}

.#{$vt-namespace}__toast--info {
	background-color: #2196f3;
	color: #fff;
}

.#{$vt-namespace}__toast--success {
	background-color: #4caf50;
	color: #fff;
}

.#{$vt-namespace}__toast--error {
	background-color: #ff5252;
	color: #fff;
}

.#{$vt-namespace}__toast--warning {
	background-color: #ffc107;
	color: #fff;
}

.#{$vt-namespace}__toast {
	padding: 11px 12px;
	min-height: 32px;
}

.#{$vt-namespace}__close-button {
	padding: 0 6px;
	margin: 0 5px;

	&:hover, &:focus {
		opacity: inherit;
		background-color: var(--button-bg-color);
	}
}