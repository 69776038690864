.popup {
	width: calc(100vw - 30px);
	top: 10px;
	left: 3px;
	z-index: 120;

	.drag {
		display: none;
	}
}

.popup {
	position: absolute;
	background-color: var(--bg-mask-color);
	border: 1px solid var(--border-color);
	border-radius: 5px;
	box-shadow: 0 0 3px #666;
	padding: 12px;
	display: none;

	.drag {
		user-select: none;
		cursor: move;
		position: absolute;
		right: 40px;
		border: 1px solid white;
		padding: 4px 6px;
		border-radius: 6px;
	}

	input {
		width: 2em;
		vertical-align: top;
	}

	h3 {
		border-bottom: 1px solid var(--border-color);
	}

	dl {
		dt {
			display: inline-block;
			margin: 0;
			padding: 0;
			margin-right: 6px;
		}
		dd {
			display: inline-block;
			margin: 0;
			padding: 0;
			margin-right: 6px;
		}
	}

	.close {
		text-align: center;
		margin: 12px;
	}

	.screenshot-container {
		top: 3px;
		text-align: center;

		img {
			max-width: 100%;
			max-height: 50vh;
		}
	}
}

#error_popup.popup {
	top: auto;
	bottom: 50px;
	left: 10px;
	width: 200px;
	text-align: center;

	span {
		color: var(--dialog-error-color);
	}
}