.dropdown,
.dropup {
	padding: 0;
	margin: 0;
	display: inline-block;
	list-style: none;
	position: relative;
	z-index: 10;

	.t {
		display: inline-block;
		border: 1px solid var(--dropdown-border-color);
		border-bottom: none;
		padding: 3px 6px;
		z-index: 101;
		position: relative;
		cursor: default;
		white-space: nowrap;
	}

	.caret {
		margin-left: 6px;
		.fa-caret-down {
			display: inline;
		}
		.fa-caret-up {
			display: none;
		}
	}
}

.dropdown.dropdown-hover,
.dropdown.dropup-hover {
	z-index: 100;
	.dropdown-data {
		display: block;
	}
	.t {
		color: var(--dropdown-hover-text-color);
		border: 1px solid var(--dropdown-hover-border-color);
		border-bottom: none;
		background-color: var(--bg-mask-color);
	}
	.caret {
		.fa-caret-down {
			display: none;
		}
		.fa-caret-up {
			display: inline;
		}
	}
}

.dropdown-data,
.dropup-data {
	display: none;
	position: absolute;
	z-index: 100;
	background-color: var(--bg-mask-color);
	padding: 6px;
	margin: 0;
	text-align: left;
	border: 1px solid var(--dropdown-hover-border-color);
	margin-top: -1px;

	max-height: 100vh;
	overflow-y: auto;

	table.simple {
		width: 100%;

		td:nth-child(1) {
			white-space: nowrap;
		}
	}

	&.dropdown-data-full-size {
		width: calc(100vw - 16px);
	}

	li {
		display: block;
		white-space: nowrap;
	}
}

.dropdown-period {
	&.period {
		margin-right: 0;
	}

	.dropdown-data {
		text-align: center;
		width: 100%;
	}

	.period {
		padding: 1px 0 2px;
	}

	#dropdown-period-value {
		color: var(--active-link-color-2);
	}
}