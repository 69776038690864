$menu-bg: var(--dialog-bg-color);
$menu-border: var(--border-color);
$item-color: var(--text-color);
$item-hover-bg: var(--dropdown-hover-border-color);
$item-hover-color: var(--dropdown-hover-text-color);

.v-context {
	&, & ul {
		background-color: $menu-bg;
		background-clip: padding-box;
		border-radius: .25rem;
		border: 1px solid $menu-border;
		//box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
		display: block;
		margin: 0;
		padding: 5px 0;
		min-width: 10rem;
		z-index: 1500;
		position: fixed;
		list-style: none;
		box-sizing: border-box;
		max-height: calc(100% - 50px);
		overflow-y: auto;

		> li {
			margin: 0;
			position: relative;

			hr {
				border-color: var(--light-border-color);
			}

			> a {
				display: block;
				padding: .5rem 1.25rem;
				font-weight: 400;
				color: $item-color;
				text-decoration: none;
				white-space: nowrap;
				background-color: transparent;
				border: 0;

				&:hover,
				&:focus {
					text-decoration: none;
					color: $item-hover-color;
					background-color: $item-hover-bg;
				}

				&:focus {
					outline: 0;
				}
			}
		}

		&:focus {
			outline: 0;
		}
	}

	&__sub {
		> a:after {
			content: "\2bc8";
			float: right;
			padding-left: 1rem;
		}

		> ul {
			display: none;
		}
	}
}