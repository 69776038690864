@use "sass:color";

$text-color: #cccccc;
$border-color: #333333;
$bg-color: #000000;
$inputs-color: darken($text-color, 65);
$col: #ffcc99;

// fixme: get rid of calculated values
:root {
	--bg-color: #000000;
	--bg-mask-color: rgba(0, 0, 0, 0.8);
	--text-color: #{ $text-color };
	--text-color-invert: invert($text-color, $weight: 90%); // todo: app
	--border-color: #{ $border-color };
	--light-border-color: #{ color.change($border-color, $alpha: 0.35) };
	--inputs-bg-color: #{ $inputs-color };

	--text-color-75-darker: #{ darken($text-color, 75) };
	--text-color-50-darker: #{ darken($text-color, 50) };
	--text-color-25-darker: #{ darken($text-color, 25) };

	--icons-filter: '';
	--chart-control-font-weight: 'bold';

	--link-color: #66bbff;
	--active-link-color: #ffcc99;
	--active-link-color-decoration: none;
	--active-link-color-grey: #{ darken(#ffcc99, 20) };
	--active-link-color-2: #{ color.adjust(darken(#ffcc99, 10), $hue: 3deg) }; // #ffbb66;

	--dropdown-border-color: #{ lighten($bg-color, 4) };
	--dropdown-hover-border-color: #{ lighten($border-color, 20) }; // not #666;
	--dropdown-hover-text-color: #{ lighten($text-color, 20) }; // not #999;

	--dialog-bg-color: #{ darken($inputs-color, 40) };
	--dialog-tablist-bg-color: #{ darken($inputs-color, 80) };
	--dialog-ok-color: #6C6;
	--dialog-error-color: #F33;
	--dialog-tablist-selected-color: #ffcc66;

	--input-background: var(--inputs-bg-color);
	--input-hover-background: #{ lighten($inputs-color, 85) }; // #474747;
	--input-hover-text-color: var(--text-color-invert);
	--highlight-text-color: #eeee00;

	--candle-green-color: #00CC00;
	--candle-red-color: #C00;

	--orderbook-bar-background-red: rgba(204, 102, 102, 0.2);
	--orderbook-bar-background-green: rgba(102, 204, 102, 0.2);

	--scrollbar-color-1: rgba(253, 152, 36, 0.5);
	--scrollbar-color-2: #0f0f10;

	--trades-feed-animation-color: #{ color.change($text-color, $alpha: 0.2) };
	--trades-feed-price-color: var(--text-color-25-darker);
	--trades-feed-volume-color: var(--text-color-25-darker);

	--button-bg-color: var(--inputs-bg-color);
	--button-hover-bg-color: var(--input-hover-background);
	--button-disabled-color: #{ color.change($inputs-color, $lightness: 30 * 1%) }; // #474747;
	--button-selected-color: #{ lighten($bg-color, 5) };
}

#app, html, body {
	background-color: var(--bg-color);
	color: var(--text-color);
}

.light {
	[data-info-type="chart"] {
		.yellow {
			color: #03E;
		}
	}
}

body {
	.dialog {
		background-color: var(--dialog-bg-color);
		color: var(--text-color);

		.tablist {
			border-bottom: 1px solid var(--border-color);
			background-color: var(--dialog-tablist-bg-color);
		}
	}

	.horizontal_line, hr {
		border-bottom: 1px solid var(--border-color);
	}

	.border {
		border: 1px solid var(--border-color);
	}

	.vertical_line {
		border-right: 1px solid var(--border-color);
	}

	g {
		color: var(--text-color-50-darker);

		g {
			color: var(--text-color-75-darker);
		}
	}

	a {
		color: var(--link-color);

		&.inherit {
			color: inherit;
		}

		&:hover {
			text-decoration: underline;
		}

		&.active {
			color: var(--active-link-color);
		}

		&.active.grey {
			color: var(--active-link-color-grey);
		}
	}

	[data-info-type="chart"] {
		.green {
			color: var(--candle-green-color);
		}

		.red {
			color: var(--candle-red-color);
		}
	}

	.chart-control__drawing, .chart-container__periods {
		a.selected {
			color: var(--active-link-color-2);
			text-decoration: var(--active-link-color-decoration);
		}

		.selected a {
			color: var(--active-link-color-2);
			text-decoration: var(--active-link-color-decoration);
		}
	}

	select, option {
		background-color: var(--button-bg-color);
		color: var(--text-color);
		border: 1px solid var(--text-color-25-darker);
	}

	.popup {
		background-color: var(--bg-mask-color);
	}

	abbr {
		border-bottom: 1px dotted var(--border-color);
	}

	/*::-webkit-scrollbar {
		background-color: var(--border-color);
		width: 16px;
		height: 13px;
	}

	::-webkit-scrollbar-button {
		&:start {
			&:decrement {
				height: 0;
				width: 0;
			}
		}

		&:end {
			&:increment {
				height: 0;
				width: 0;
			}
		}
	}

	::-webkit-scrollbar-track-piece {
		background-color: #222;
		border: 1px solid #555;
	}

	::-webkit-scrollbar-thumb {
		&:vertical {
			height: 50px;
			background: -webkit-gradient(linear, left top, right top, color-stop(0%, #4d4d4d), color-stop(100%, var(--border-color)));
			border: 1px solid #0d0d0d;
			border-top: 1px solid var(--text-color-50-darker);
			border-left: 1px solid var(--text-color-50-darker);
		}

		&:horizontal {
			width: 50px;
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4d4d4d), color-stop(100%, var(--border-color)));
			border: 1px solid #1f1f1f;
			border-top: 1px solid var(--text-color-50-darker);
			border-left: 1px solid var(--text-color-50-darker);
		}
	}*/

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		border-radius: 0;
		background-color: var(--scrollbar-color-2);
	}

	::-webkit-scrollbar-corner {
		background: var(--scrollbar-color-2);
	}

	::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-color-1);
		border-radius: 0;
		border: 1px solid var(--scrollbar-color-2);
	}
}