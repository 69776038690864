@keyframes orderBookSlideDown {
  from {
    max-height: 0;
    background: #333;
  }
  30% {
    max-height: 13px;
  }
  to {
    max-height: 13px;
    background: #333;
  }
}

@keyframes orderBookSlideDownLight {
  from {
    max-height: 0;
    background: #CCC;
  }
  30% {
    max-height: 13px;
  }
  to {
    max-height: 13px;
    background: #CCC;
  }
}

@keyframes orderBookSlideUp {
  from {
    max-height: 13px;
  }
  to {
    max-height: 0;
  }
}

@keyframes tradeLogDropDown {
  from {
    max-height: 0;
    background: #333;
  }
  50% {
    max-height: 13px;
  }
  to {
    background: #333;
  }
}

@keyframes tradeLogDropDownLight {
  from {
    max-height: 0;
    background: #CCC;
  }
  50% {
    max-height: 13px;
  }
  to {
    background: #CCC;
  }
}

@keyframes tradeLogDropDownTranslate {
  from {
    transform: translateY(-100%);
    background: #333;
  }
  50% {
    transform: translateY(0);
  }
  to {
    background: #333;
  }
}