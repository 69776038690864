button, .button {
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid var(--border-color);
	background-color: var(--border-color);
	color: var(--text-color);
	padding: 4px 8px;

	&.selected {
		background-color: var(--input-hover-background);
		color: var(--input-hover-text-color);

		&:hover {
		//	background-color: var(--button-selected-color);
		}
	}

	&:hover {
		background-color: var(--input-hover-background);
	}

	&:active {
		background-color: var(--border-color);
	}

	&:disabled {
		cursor: not-allowed;
		background-color: var(--button-disabled-color);
		color: var(--inputs-bg-color);
	}
}

.button-black {
	background: var(--bg-mask-color);
}

.button-sm {
	padding: 2px 4px;
	font-size: 80%;
}

button, .button {
	border: 1px solid var(--border-color);
	background-color: var(--button-bg-color);
	color: var(--text-color);
}

.button-container {
	button + button,
	.button + button,
	button + .button,
	.button + .button {
		margin-left: 5px;
	}
}