#assist {
  display: none;
  overflow-y: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 320px;
  height: 32px;
  padding: 0 4px;
  border-left: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  font-size: 11px;
  line-height: 16px;
  color: var(--border-color);
  background-color: var(--bg-mask-color);
}