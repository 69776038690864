.navbar {
  .nav {
    display: flex;
    align-items: center;

    .ticker {
      display: none;
    }

    .logo .dropdown .t {
      padding: 0 6px;

      .caret {
        margin-left: 0
      }
    }

    .dropdown .t {
      padding: 3px 6px 3px;
    }

    .logo {
      margin-right: 0;

      img {
        height: 28px;
        margin-bottom: -9px;
      }

      > a {
        display: none;
      }
    }
  }
}

.navbar {
  font-family: Arial, sans, serif;
  font-size: 12px;

  /*border-bottom: 1px solid var(--border-color);*/

  .nav {
    margin: 0;
    list-style: none;
    padding: 0 0 0 3px;

    li {
      display: inline-block;
      margin-right: 9px;
      border-right: 1px solid var(--border-color);
      padding-right: 9px;
    }

    li:last-child {
      border-right: none;
    }

    li#nav-charts {
      margin-right: 0;
    }

    .logo {
      margin-left: 0;
      font-size: 14px;
      line-height: 40px;

      .dropdown-data {
        min-width: 350px;
      }

      .row {
        display: flex;
        padding: 0;
        width: 100%;
        /*  width: calc(100% + 2.0rem);*/
        flex-direction: row;
        margin-left: -1.0rem;
      }

      .row .column {
        margin-bottom: inherit;
        padding-left: 2rem;

        &:nth-child(2) {
          padding-left: 1rem;
        }
      }
    }

    li.ticker {
      span {
        margin-left: 6px;
        font-family: Consolas, monospace;
        font-size: 12px;
      }
      span.eprice {
        margin-left: 0;
      }
    }

    li.passport {
      font-size: 13px;
      float: right;
      line-height: 32px;
      height: 32px;
      margin: 0;
      padding: 0;

      > div {
        margin-right: 8px;
        display: inline-block;
      }
    }
  }

  table.simple {
    width: 100%;
  }
}