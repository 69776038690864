.auth-page {
	font-family: Consolas, monospace;
	width: 100%;

	.error, .ok {
		padding: 5px;
	}

	> div {
		padding: 20px;
		width: min-content;
		margin: 100px auto;
	}

	form {
		width: 100%;

		button {
			margin-top: 1em;
		}

		label {
			margin-left: 1em;
			margin-right: 2em;
		}
	}

	table {
		width: 100%;
		margin: 10px 0 5px;
	}
}

.auth-page__body {
	margin: 15px 0;
}

.auth-page__footer {
	margin-top: 15px;

	a {
		text-wrap: none;
	}
}

.auth-page__oauth {
	margin-top: 5px;

	a {
		display: flex;
		align-items: center;
		//     justify-content: center;
	}

	i + span {
		margin-left: 5px;
	}

	a + a {
		margin-right: 15px;
	}
}

.can-have-error {
	display: flex;
	flex-direction: column;
}