.full-screen-only {
  display: none !important;
}

html {
  &:fullscreen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }

  &:-moz-full-screen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }

  &:-webkit-full-screen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }
}