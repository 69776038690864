.center {
  text-align: center;
}

.hide {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.unsel, .unselectable  {
  user-select: none;
}

.pointer {
  cursor: pointer;
}