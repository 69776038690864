input {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
}

input[type=text],
input[type=email],
input[type=password],
{
  width: 12em;
}

input[type=checkbox] {
  vertical-align: middle;
}

input[type=button] {
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--input-hover-background);
  }

  &:active {
    background-color: var(--input-background);
  }
}

input[type=submit] {
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--input-hover-background);
  }

  &:active {
    background-color: var(--input-background);
  }
}

input.has-error {
    border-color: var(--dialog-error-color);
}