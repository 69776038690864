/*
$fa-font-path: "~font-awesome/fonts";

@import "~font-awesome/scss/font-awesome";
*/

.ic {
  font-family: $icon-font-family;
  font-style: normal;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1.00em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}

b {
  font-weight: bold;
}