$icon-font-family: 'FontAwesome';

$forceMobile: false;

$breakpoint-mobile: 400px !default;
$breakpoint-phablet: 550px !default;
$breakpoint-tablet: 750px !default;
$breakpoint-desktop: 1000px !default;
$breakpoint-desktop-hd: 1200px !default;

@if $forceMobile {
  $breakpoint-mobile: 1px;
  $breakpoint-phablet: 2px;
  $breakpoint-tablet: 3px;
  $breakpoint-desktop: 4px;
  $breakpoint-desktop-hd: 1200px;
}