.bw-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #090;
  vertical-align: baseline;
  border-radius: 5px;
}

.good {
  color: #090;

  .bw-circle {
    background-color: #090;
  }
}

.normal {
  color: #990;

  .bw-circle {
    background-color: #990;
  }
}

.bad {
  color: #900;

  .bw-circle {
    background-color: #900;
  }
}