@import 'tachyons/css/tachyons.css';

@import './sass-utils/variables';
@import './sass-utils/helpers';
@import './sass-utils/functions';
@import './sass-utils/mixins';

/*$fa-font-path: "/home/dev/Kofta/frontend/assets/fonts/fa6/webfonts";

@import '../fonts/fa6/scss/fontawesome.scss';
@import '../fonts/fa6/scss/regular.scss';
@import '../fonts/fa6/scss/light.scss';
@import '../fonts/fa6/scss/solid.scss';
@import '../fonts/fa6/scss/thin.scss';
@import '../fonts/fa6/scss/duotone.scss';*/

@import './vendors/simplebar'; // fixme?
@import './vendors/vue-context';
@import './vendors/socials-colors';
@import './vendors/vue-toastification';

@import './base/reset';
@import './base/typography';
@import './base/animations';

@import 'themes/theme';

@import './components/assist';
@import './components/buttons';
@import './components/chart';
@import './components/dialog';
@import './components/dropdown';
@import './components/fullscreen';
@import './components/inputs';
@import './components/popups';
@import './components/status_icon';
@import './components/widgets';
@import './components/table';

//@import './layout/classic_orderbook';
//@import './layout/classic_sidebar';
//@import './layout/classic_trades_feed';
@import './layout/header';
//@import './layout/footer';
@import './layout/navbar';

@import './pages/market';
@import './pages/auth';

html, body, #app {
	height: 100%;
	width: 100%;
}

.vm--overlay {
	z-index: 110;
}

body {
	margin: 0;
	font-size: 14px;
	font-family: Arial, sans, serif;
	line-height: 18px;

	.vue-resizable-handle {
		display: none;
	}

	&.edit-layout {
		overflow: auto;

		.vue-resizable-handle {
			display: block;
		}
	}
}

.no-user-select {
	user-select: none;
}

.flex-center {
	justify-content: center;
}

.flex-vertical-center {
	align-items: center;
}

.fill-height {
	height: 100%;
}

.flex-float-right {
	margin-left: auto;
}

.display-block {
	display: block;
}

.remove-from-flow {
	margin-left: -9999px;
	position: absolute;
}

table.form {
	border-collapse: collapse;
	margin: auto;

	td {
		padding: 4px;
		margin: 0;
	}

	th {
		padding: 4px;
		margin: 0;
		font-weight: normal;
		text-align: right;
	}

	.message {
		text-align: center;
		color: green;
	}

	.error {
		color: red;
	}
}

a {
	color: #0D86FF;
	text-decoration: none;
	cursor: pointer;

	&.direct_address {
		color: #6C6;
	}
}

.sep {
	border-right: 1px solid var(--border-color);
	height: 16px;
	vertical-align: middle;
}

.subsep {
	border-right: 1px solid var(--border-color);
	height: 16px;
	vertical-align: middle;
	margin-right: 6px;
}

.horiz {
	display: flex;
	align-items: center;

	padding: 0;
	margin: 0;

	ul {
		display: inline-block;
	}

	.last {
		margin-right: 0;
	}

	input {
		vertical-align: middle;
	}

	select {
		vertical-align: middle;
	}

	button, .button {
		vertical-align: middle;
	}
}

.control {
	font-family: Arial, sans, serif;
	font-size: 12px;
	font-weight: bold;
	border-bottom: 1px solid var(--border-color);

	select {
		font-size: 12px;
		font-family: sans-serif;
		outline: none;
	}

	input {
		font-size: 12px;
		font-family: sans-serif;
		outline: none;
	}

	button {
		font-size: 12px;
		font-family: sans-serif;
		outline: none;
	}

	.button {
		font-size: 12px;
		font-family: sans-serif;
		outline: none;
	}

	[class*="fa-"] {
		vertical-align: middle;
	}
}

g {
	color: var(--text-color);
}

abbr {
	cursor: help;
	font-weight: normal;
	border-bottom: 1px dotted var(--border-color);
}

select {
	cursor: pointer;
}

#donation {
	color: #333;
	font-size: 10px;
	font-family: Consolas, monospace;
	vertical-align: top;
}

.nav-markets {
	font-family: Arial, sans, serif;
	a {
		margin-right: 6px;
	}
}

#markets {
	right: 0;
	font-size: 11px;
	text-align: center;
	font-weight: normal;
	a {
		margin: 0 3px;
	}
	.currency {
		font-size: 12px;
	}
	div {
		display: inline-block;
		font-family: Consolas, monospace;
		margin-right: 12px;
		margin-left: 0;
		text-align: left;
	}
}

table.simple {
	font-weight: normal;
	border-collapse: collapse;
	font-size: 12px;
	td {
		padding: 6px;
		border-bottom: 1px solid var(--border-color);
	}
	tr {
		&:last-child {
			td {
				border-bottom: none;
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			margin-right: 12px;
			color: var(--link-color);
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}

		li.active {
			color: var(--active-link-color);
		}
	}
}

.difficulty {
	position: absolute;
	right: 5px;
	top: 5px;
	font-family: Tahoma, Arial, sans, serif;
	font-size: 11px;
	text-align: right;

	table {
		margin: auto;
		border: 1px solid var(--border-color);
		border-collapse: collapse;
		text-align: left;
		background-color: var(--dropdown-border-color);

		td {
			overflow: hidden;
			height: 16px;
			padding: 0 4px;
			line-height: 16px;
		}
	}

	span {
		font-family: Consolas, monospace;
	}
}

.address {
	font-family: Consolas, monospace;
}

.del {
	text-decoration: line-through;

	[class*="fa-"] {
		text-decoration: none;
	}
}

.help {
	position: absolute;
	left: 0;
	top: 0;
	background-color: var(--dropdown-border-color);
	font-size: 11px;
	padding: 8px;
	border: 1px solid var(--border-color);
	display: none;
	dl {
		&:first-child {
			margin-top: 0;
		}
	}
}

code {
	font-family: Consolas, monospace;
}

.desktop-only {
	display: none !important;
}

.difficulty {
	display: none;
}

#nav-charts {
	font-weight: normal;
	display: none;
}

.cursor-pointer {
	cursor: pointer;
}

/* hideable - display - size */
.hideable-inline-block-mobile {
	display: none !important;
}

.hideable-block-mobile {
	display: none !important;
}

.hideable-inline-block-phablet {
	display: none !important;
}

.hideable-block-phablet {
	display: none !important;
}

.hideable-inline-block-tablet {
	display: none !important;
}

.hideable-block-tablet {
	display: none !important;
}

.hideable-inline-block-desktop {
	display: none !important;
}

.hideable-block-desktop {
	display: none !important;
}

.hideable-inline-block-desktop-large {
	display: none !important;
}

.hideable-block-desktop-large {
	display: none !important;
}

.navbar .nav .logo {
	margin-left: 3px;
	margin-right: 5px;

	> a {
		display: inline;
	}

	.t [class*="fa-"] {
		margin-left: 10px;
		margin-right: 6px;
	}
}

.capitalize-first-letter::first-letter {
	text-transform: capitalize;
}

.popup {
	//width: 60%;
	//left: 20%;
	//top: 20%;

	.drag {
		display: block;
	}
}

.dark {
	.popup {
		background-color: rgba(0, 0, 0, 0.6);
	}
}

.periods {
	.tf-label {
		margin-left: 0;
		display: inline-block;
	}

	li.period:nth-child(2) {
		margin-left: 0;
	}
}

.no-scrollbar {
	-ms-overflow-style: none; /* Edge, Internet Explorer */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Chrome, Safari, Opera */
	}
}

/* hideable - display - size1-size2 */

/* Larger than mobile */
@media (min-width: $breakpoint-mobile) {
	.hideable-inline-block-mobile {
		display: inline-block !important;
	}

	.hideable-block-mobile {
		display: block !important;
	}
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: $breakpoint-phablet) {
	#sidebar {
		width: 100%;

		#expand_sidebar {
			display: none;
		}
	}

	.hideable-inline-block-phablet {
		display: inline-block !important;
	}

	.hideable-block-phablet {
		display: block !important;
	}
}

@media (min-width: $breakpoint-phablet) and (max-width: $breakpoint-tablet) {
	.float-right-phablet {
		float: right;
	}

	.visible-inline-block-phablet-tablet {
		display: inline-block !important;
	}

	.hideable-inline-block-phablet-tablet {
		display: none !important;
	}
}

/* Larger than tablet */
@media (min-width: $breakpoint-tablet) {
	.horizontal_line {
		display: none;
	}

	.hideable-inline-block-tablet {
		display: inline-block !important;
	}

	#nav-charts {
		display: inline-block;
	}

	.nav-markets .dropdown-data table.simple {
		td:nth-child(2) {
			white-space: nowrap;
		}
	}

	.dropdown-data.dropdown-data-full-size {
		width: auto;

		table {
			white-space: nowrap;
		}
	}
}

/* Larger than desktop */
@media (min-width: $breakpoint-desktop) {
	.dropdown-data {
		width: auto;

		table.simple {
			width: auto;
		}
	}

	#now {
		padding-right: 6px;
	}

	header .inner > ul.horiz {
		padding-left: 6px;

		li {
			padding: 0;
		}
	}

	.navbar {
		.nav li.ticker {
			display: inline-block;
		}
	}

	.hideable-block-desktop {
		display: block !important;
	}

	.hideable-inline-block-desktop {
		display: inline-block !important;
	}
}

/* Larger than Desktop HD */
@media (min-width: $breakpoint-desktop-hd) {
	.desktop-only {
		display: block;
	}

	.difficulty {
		display: block;
	}

	.hideable-block-desktop-large {
		display: block !important;
	}

	.hideable-inline-block-desktop-large {
		display: inline-block !important;
	}
}