header {
  .horiz {
    padding: 0;

    ul {
      display: inline-block;
    }

    > div {
      padding: 4px 0;

      &.sep {
        padding: 0;
      }

      &.subsep {
        padding: 0;
      }
    }

    .dropdown-data {
      padding: 3px;
    }
  }
}