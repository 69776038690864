.dialog {
	position: absolute;
	left: 50%;
	top: 40%;
	width: 640px;
	padding: 8px;
	border: 1px solid var(--border-color);
	font-family: Arial, sans, serif;
	font-size: 13px;
	z-index: 111;
	display: none;

	.tablist {
		margin-bottom: 8px;
		cursor: move;

		.tab {
			display: inline-block;
			padding: 8px;
			font-size: 12px;
			text-align: center;
			margin-right: 6px;
		}

		.close {
			cursor: pointer;
			width: auto;
			padding: 8px;
			text-align: right;
			float: right;
		}

		.selected {
			color: var(--dialog-tablist-selected-color);
		}

		i.icon-ok {
			color: var(--dialog-ok-color);
		}

		table {
			border-collapse: collapse;
			td {
				padding: 2px 4px;
				&:first-child {
					text-align: right;
					width: 160px;
				}
			}
		}
	}

	.tablist-one {
		.tab {
			width: auto;
		}
	}

	h3 {
		margin-top: 0;
		margin-bottom: 8px;
		padding-top: 4px;
		font-size: 12px;
	}

	dl {
		margin-top: 0;
	}

	ol {
		margin: 0;
		padding: 0;
		li {
			margin-left: 40px;
		}
	}

	button {
		font-family: consolas, monospace;
		margin-right: 4px;
	}

	input {
		font-family: consolas, monospace;
	}

	.content_alarm {
		padding: 0 10px;

		.price {
			width: 6em;
		}

		.all {
			td {
				padding-top: 10px;
				text-align: center;
			}
		}
	}
}

form {
	.error {
		color: var(--dialog-error-color);
		text-align: center;
	}

	.ok {
		color: var(--dialog-ok-color);
		text-align: center;
	}

	table {
		td {
			padding: 4px;
		}
	}
}

.dialog-auto-size {
	width: auto;
	height: auto;

	form {
		table {
			td {
				&:first-child {
					width: auto;
				}
			}
		}
	}
}